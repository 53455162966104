@use "./colors" as *;
@use "./variables" as *;
@use "./mixins" as *;
@use "./fonts" as *;

* {
  box-sizing: border-box;
  margin: 0;
  border: 0;
  padding: 0;
}

html {
  font-size: 100%;
}

body {
  font-family: "CartoGothic", Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: $black;
}

//headers
h1 {
  font-size: 24px;
  letter-spacing: 1.5px;

  @include tablet {
    font-size: 32px;
    letter-spacing: 2px;
  }
}

//homepage intro
h2 {
  font-family: "Lora", Georgia, "Times New Roman", Times, serif;
  font-size: 24px;
  line-height: 1.25rem;
  letter-spacing: 0.5px;

  @include tablet {
    font-size: 42px;
    line-height: 2rem;
    letter-spacing: 1px;
  }
}

//nav
h3 {
  font-size: 16px;
  letter-spacing: 4px;
}

//project title
h4 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;

  @include tablet {
    font-size: 24px;
  }
}

//project subheaders
h5 {
  font-size: 24px;
  font-weight: 400;

  @include tablet {
    font-size: 26px;
  }
}

p {
  text-align: left;
  font-size: 14px;
  line-height: 1.25rem;
  text-align: left;

  @include tablet {
    font-size: 16px;
    text-align: left;
    line-height: 1.5rem;
  }
}

.main-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.apparel {
  width: 100%;
  padding-bottom: 6rem;
}

.spacer {
  border: solid 3px $tea-green;
  width: 80%;
  margin: 2rem 0 6rem;
}
